<template>
  <div id="map" class="map"></div>
</template>

<script>
import L from "leaflet";
import axios from "axios"; // Ensure axios is installed for HTTP requests
import "leaflet/dist/leaflet.css";

// Import marker icon and shadow
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerIcon2x from "leaflet/dist/images/marker-icon-2x.png";
import markerIconShadow from "leaflet/dist/images/marker-shadow.png";

// Fix marker icon path issues
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerIconShadow,
});

export default {
  name: "LeafletMap",
  props: {
    address: {
      type: String,
      required: false,
      default: null,
    }, // Pass the full address as a prop
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      // Initialize the map with a default view
      const map = L.map("map").setView([51.505, -0.09], 13);

      // Add OpenStreetMap tiles
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
        attribution: "© OpenStreetMap contributors",
      }).addTo(map);

      // Perform geocoding
      if (this.address) {
        this.geocodeAddress(this.address, map);
      }
    },
    geocodeAddress(address, map) {
      const url = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
        address
      )}`;

      axios
        .get(url)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            const { lat, lon } = response.data[0];
            const coordinates = [lat, lon];

            // Add a marker at the geocoded location
            L.marker(coordinates).addTo(map).bindPopup(address).openPopup();

            // Center the map on the geocoded location
            map.setView(coordinates, 13);
          }
        })
        .catch((error) => {
          console.error("Geocoding error:", error);
        });
    },
  },
};
</script>

<style scoped>
.map {
  height: 400px;
  width: 100%;
}
</style>
