<template>
  <div class="main-wrapper">
    <Navbar :show-search="false" @search="handleSearch" />
    <div class="container">
      <div class="row">
        <button class="back-button" @click="backNavigation">
          <v-icon>mdi-chevron-left</v-icon>
          <span>Voltar</span>
        </button>
      </div>
      <div class="row">
        <template v-if="loading">
          <div v-for="n in 1" :key="n" class="col-12">
            <VSkeletonLoader type="card" />
          </div>
        </template>
        <template v-else>
          <div
            v-for="therapist in therapists"
            :key="therapist.therapistId"
            class="col-12"
          >
            <CardDetails
              :profile-image="getPhoto(therapist)"
              :name="therapist.name"
              :profession="therapist.additionalDetail"
              :price="therapist.sessionValue"
              :description="therapist.bio"
              :available-times="therapist.freeAppointments"
              :rating="therapist.rating"
              :reviews-count="therapist.reviewsCount"
              :therapist-id="therapist.therapistId"
              :abrabio-number="therapist.abrabioNumber"
              :formed-by-parmagnetico="therapist.formedByParmagnetico"
            />
          </div>
        </template>
      </div>
      <div v-if="address" class="row">
        <LeafletMap :address="address" />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/domains/find-therapist/nav-bar/NavBar.vue";
import CardDetails from "@/domains/find-therapist/therapist-details/card-details/CardDetails.vue";
import Footer from "@/domains/find-therapist/footer/Footer.vue";
import { findNextTenPublicTherapists } from "@/services/external/therapists-service";
import { VSkeletonLoader } from "vuetify/lib";
import LeafletMap from "@/components/shared/LeafletMap.vue";
export default {
  name: "TherapistDetails",
  components: {
    Navbar,
    CardDetails,
    Footer,
    VSkeletonLoader,
    LeafletMap,
  },
  data: () => ({
    hours: ["08:00", "09:00", "10:00", "11:00"],
    therapists: [],
    pageNumber: 1,
    searchWord: null,
    location: null,
    loading: false,
    loadingMore: false,
    moreResultsAvailable: true,
    address: null,
  }),
  async created() {
    this.loading = true;
    const therapists = await this.getTherapists();
    this.therapists = therapists;
    if (therapists.length > 0) {
      this.address = this.getAddress(therapists[0]);
    }
    this.loading = false;
  },

  methods: {
    async getTherapists() {
      const routeParamId = this.$route.params.id;
      const result = await findNextTenPublicTherapists(
        this.pageNumber,
        this.searchWord,
        this.location,
        routeParamId
      );
      return result.data;
    },
    async handleSearch({ searchWord, location }) {
      console.log("not implemented yet");
    },
    backNavigation() {
      this.$router.go(-1);
    },
    getPhoto(therapist) {
      if (therapist.photo) {
        return therapist.photo;
      }
      return require("@/assets/user.svg");
    },

    getAddress(therapist) {
      if (therapist.city && therapist.state) {
        return `${therapist.city}, ${therapist.state}`;
      }
    },
  },
};
</script>

<style>
.main-wrapper {
  background-color: white;
}
.container {
  margin-top: 20px;
}

.load-more-button {
  background-color: transparent;
  color: #265acc;
  border: 1px solid #265acc;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  gap: 10px;
}

.load-more-button:hover {
  background-color: #265acc;
  color: white;
}

.disabled-button {
  background-color: #e0e0e0;
  color: #9d9d9d;
  border: 1px solid #e0e0e0;
  cursor: not-allowed;
}

.disabled-button:hover {
  background-color: #e0e0e0;
  color: #9d9d9d;
}

.load-more-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.end-of-results {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  color: #9d9d9d;
  font-size: 12px;
  font-weight: 400;
}

.back-button {
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0px;
  border-radius: 8px;
  padding: 6px 10px 6px 6px;
  font-weight: 600;
  font-size: 16px;
  margin-top: 16px;
  margin-left: 8px;
}
.back-button:hover {
  background-color: #e2e2e2;
}
</style>
